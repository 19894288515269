import { navigate } from "gatsby";
import React from "react";
import clsx from "clsx";
import { Col, Image, Row } from "react-bootstrap";
import "../../../styles/components/Blog/Card/style.scss";

const formatDate = (input) => {
  const result = input.split("-");
  return `${result[1]}/${result[2]}/${result[0]}`;
};

const BlogComponent = ({
  title,
  author,
  excerpt,
  imageUrl,
  date,
  category,
  navigation,
  isLast,
}) => {
  const handleNavigate = () => {
    navigate(navigation);
  };
  return (
    <>
      <Row className="blog_items">
        <Col md={6} className="pr-20">
          <div className="blog-content">
            <h5
              className={clsx("blog-title text-md-start", {
                "mt-0": !imageUrl,
              })}
            >
              {title}
            </h5>
            <p className="blog-subtitle text-md-start">{author}</p>
            <p className="blog-date-category d-none d-md-block">
              {date && formatDate(date)}
              {date && category && " | "}
              {category}
            </p>
            <p className="blog-description text-md-start">{excerpt}</p>
            <a
              onClick={handleNavigate}
              className="blog-learn-more text-md-start"
            >
              Read more
            </a>
          </div>
        </Col>
        <Col
          className="pl-20"
          md={{ span: 6, order: 1 }}
          xs={{ span: 12, order: "first" }}
          sm={{ span: 12, order: "first" }}
        >
          {imageUrl && (
            <Image
              src={imageUrl}
              className="blog-image img-fluid"
              width={"100%"}
              height={"100%"}
            />
          )}
        </Col>

        {!isLast && (
          <Col md={{ span: 12, order: 2 }} className="blog_separator">
            <hr />
          </Col>
        )}
      </Row>
    </>
  );
};

export default BlogComponent;
